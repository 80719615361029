<template>
  <div>
    <b-row class="mt-1">

      <b-col cols="12" md="12" lg="3">
        <b-form-group :label="msg('Firstname')" label-for="ksv-company">
          <b-form-input readonly v-model="client.firstname"></b-form-input>

        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="3">
        <b-form-group :label="msg('Lastname')" label-for="ksv-company">
          <b-form-input readonly v-model="client.lastname"></b-form-input>

        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="3">
        <b-form-group :label="msg('Birthday')" label-for="ksv-company">
          <b-form-input readonly v-model="client.birthday"></b-form-input>

        </b-form-group>
      </b-col>


      <b-col v-if="hasVipClubAdmin" cols="12" md="3" lg="3" class="mb-md-0 mb-2">

        <b-form-group :label="msg('Status')" label-for="realEstate-branch">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="vipClub.status"
              :options="[
                      {value:'NEW',label:msg('NEW')},
                      {value:'FINANZHELD',label:msg('FINANZHELD')},
                      {value:'WOHNDECKUNG',label:msg('WOHNDECKUNG')},
                      {value:'WOHNERWERBER',label:msg('WOHNERWERBER')},
                      {value:'CANCELLED',label:msg('CANCELLED')},
                  ]"
              class="w-100"
              label="label"
              :reduce="(status) => status.value"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>

      <b-col v-else cols="12" md="3" lg="3" class="mb-md-0 mb-2">

        <b-form-group :label="msg('Status')" label-for="realEstate-branch">
          <p style="color:salmon; font-weight: bold;font-size: larger">
            {{ vipClub.status }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row  v-if="hasVipClubAdmin">

      <b-col cols="12" md="12" lg="3">
        <b-form-group :label="msg('Branch')" label-for="campaign-branch">
          <v-select

              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="vipClub.buid"
              :options="allBranches"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              :clearable="false"
              @option:selected="onValueUpdated"

          />
        </b-form-group>
      </b-col>


      <b-col cols="3" md="3" lg="3">
        <div class="mt-2">
          <b-form-group :description="msg('Paid') ">

            <b-form-checkbox
                v-model="vipClub.paid"
                name="check-button" switch
                @change="onValueUpdated">
            </b-form-checkbox>
          </b-form-group>
        </div>

      </b-col>

      <b-col cols="3" md="3" lg="3">
        <div class="mt-2">
          <b-form-group :description="msg('Active') ">

            <b-form-checkbox
                v-model="vipClub.active"
                name="check-button" switch
                @change="onValueUpdated">
            </b-form-checkbox>
          </b-form-group>
        </div>

      </b-col>


      <b-col cols="3" md="3" lg="3">
        <b-form-group :label="msg('Welcome email')" label-for="email">

          <b-input-group>
            <b-form-input  readonly id="email" v-model="client.email"/>
            <b-input-group-append>
              <b-button variant="outline-primary" v-b-tooltip :title="msg('Send Welcome email to client')"
                        @click="sendRegEmail">
                SEND
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-else>

      <b-col  cols="12" md="3" lg="3" class="mb-md-0 mb-2">

        <b-form-group :label="msg('Active')" label-for="realEstate-branch">
          <p style="color:salmon; font-weight: bold;font-size: larger">
            {{ vipClub.active?msg('yes'):msg('no') }}
          </p>
        </b-form-group>
      </b-col>

      <b-col  cols="12" md="3" lg="3" class="mb-md-0 mb-2">

        <b-form-group :label="msg('Paid')" label-for="realEstate-branch">
          <p style="color:salmon; font-weight: bold;font-size: larger">
            {{ vipClub.paid?msg('yes'):msg('no') }}
          </p>
        </b-form-group>
      </b-col>

      <b-col cols="3" md="3" lg="3">
        <b-form-group :label="msg('Welcome email')" label-for="email">

          <b-input-group>
            <b-form-input  readonly id="email" v-model="client.email"/>
            <b-input-group-append>
              <b-button variant="outline-primary" v-b-tooltip :title="msg('Send Welcome email to client')"
                        @click="sendRegEmail">
                SEND
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="hasVipClubAdmin">

      <b-col class="mt-3" cols="12" md="12" lg="12" v-for="(answer,question) in vipClub.typeFormData"
             v-bind:key="question">

        <h4 style="">{{ question }}</h4>
        <b-link v-if="answerHasLink(answer)" target="_blank" :href="answer" style="color: #ef3f03">{{ answer }}</b-link>

        <p v-else  style="color: darksalmon">{{ answer }}</p>
      </b-col>

    </b-row>
    <b-row>

      <b-col cols="12" class="mb-2 mt-1">
        <b-form-group :label="msg('Send email to the customer')" label-for="vipEmail">
          <quill-editor
              spellcheck="false"
              class="vip-description"
              v-bind:value="emailToSend"
              @change="onEditorChange"
          />
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'vipClubManagement',
  data: () => ({
    emailToSend:""
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('vipClub', ['hasVipClubAdmin']),
    ...mapGetters('data', ['getAllBranches']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('data', ['getAllCountries', 'getAllLanguage']),


    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatuses() {
      return Object.values(this.allStatus)
    },
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    vipClub: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    ...mapActions('vipClub', {_sendRegMail: 'sendRegMail'}),
    sendRegEmail() {
        this._sendRegMail({vipClubId: this.vipClub.id})

    },
    answerHasLink(answer){

      return answer.includes("https://")

    },
    onEditorChange({quill, html, text}) {
      //Little hack in order to prevent onValueUpdated be triggered
      if (this.emailToSend === html)
        return;
      this.emailToSend = html;
      this.vipClub.emailToSend=this.emailToSend;
      this.onValueUpdated({quill, html, text})
    },
    onValueUpdated(args) {
      this.$emit('change', args)
    }
  }
}
</script>

<style>
.vip-description .ql-editor {
  min-height: 200px;
}
</style>
