<template>
  <div class="realEstate-page">
    <div v-if="loaded === true">
      <!-- RealEstate statistics -->
      <b-row class="realEstate-statistics">
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="EyeIcon" statistic="coming soon" statistic-title="Actually viewed"/>
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="ShoppingBagIcon" color="success" statistic="coming soon"
                                     statistic-title="Booked but canceled"/>
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="MessageSquareIcon" color="warning" statistic="coming soon"
                                     statistic-title="Rented"/>
        </b-col>
        <b-col lg="3" sm="6">
          <statistic-card-horizontal icon="UserIcon" color="info" statistic="coming soon" statistic-title="Rating"/>
        </b-col>
      </b-row>
      <b-row>
        <!--Managemnt details -->
        <b-col cols="12" class="col-12">
          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                <div class="d-flex">
                  <feather-icon icon="PackageIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ msg('VipClub details').toUpperCase() }}
                  </h4>
                </div>
                <vip-management v-model="vipClub" @change="()=>vipClubUpdated=true" :client="vipClient"/>
              </div>
            </b-card-body>
          </b-card>
        </b-col>


      </b-row>
      <div class="update-realEstate" v-bind:class="{'active':(vipClubUpdated===true)}">
        <b-button variant="success" class="" @click="sendEmail">{{ msg("Send email") }}</b-button>
        <b-button variant="primary" class="mx-1" @click="updateVipClub">{{ msg("Save") }}</b-button>
        <b-button variant="warning" @click="loadVipClub">{{ msg("Cancel") }}</b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-items-center" style="min-height: 600px">
        <div class="d-inline-block w-100 text-center">
          <b-spinner class="mx-1" variant="primary"/>
          <strong>{{ msg('Loading') }}...</strong>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import VipManagement from "@/views/realEstate/vipClub/vipManagement";

export default {
  name: 'editVipClub',
  components: {
    VipManagement,
    StatisticCardHorizontal
  },
  data() {
    return {
      loaded: false,
      error: null,
      vipClub: {
        name: '',


      },
      vipClient: null,
      vipClubUpdated: false,
      //Listeners
      listeners: {
        submit: () => {
        }
      },
    }
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    },
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getAllLanguage', 'getAllBranches', 'getAllCountries', 'getAdminRoleId']),
    canSubmit: {
      get() {
        return false
      },
      save(v) {
        this.$emit('update:canSave', v)
      }
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {
    if (this.$route.params.id == null) {
      return this.error = 'Invalid vipClub id'
    }
    this.id = this.$route.params.id;
    this.onLoad()
  },

  methods: {
    ...mapActions('vipClub', {_getVipClub: 'getVipClubById', _updateVipClub: 'updateVipClub', _sendEmail: 'sendEmail',
    }),
    ...mapActions('clients', {_getClientById: 'get'})
    ,
    onLoad() {
      this.loaded = false

      this.loadVipClub()

    },

    loadVipClub() {
      let $this = this
      $this.loaded = false
      this._getVipClub($this.id)
          .then((vipClub) => {
            let $$this = $this;
            $this.vipClub = vipClub
            //Set page as loaded
            $this.getClientById(vipClub.clientId);
            $this.loaded = true
            $this.vipClubUpdated = false;
          }, (error) => {
            $this.error = error
          })
    }
    ,
    getClientById(clientId) {
      let $this = this

      this._getClientById({id: clientId}).then((client) => {
        $this.vipClient = client["client"]
      })

    },
    sendEmail() {
      let $this = this
      let vipClub = {...this.vipClub};

      this._sendEmail(vipClub)
          .then(
              (a) => $this.$emit('submitted', {
                status: true,
                server: a
              }),
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },
    updateVipClub() {
      let $this = this
      this._updateVipClub(this.vipClub)
          .then(
              (a) => $this.$emit('submitted', {
                status: true,
                server: a
              }),
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },
    validate(field) {
      return this.$v.vipClub[field] != null && this.$v.vipClub[field].$error === true
    },
    submit() {
      if (this.id !== 0) {
        return this.updateVipClub()
      }
      return this.createVipClub()

    },


  },

}
</script>

<style>
.realEstate-page .update-realEstate {
  position: fixed;
  bottom: 5%;
  z-index: 100;
  transition: all 0.5s ease;
  right: 79px;
  opacity: 0;
}

.realEstate-page .update-realEstate.active {
  opacity: 1;
}

.realEstate-page .update-realEstate .btn-primary {
  -webkit-box-shadow: 0 1px 20px 1px #7367f0 !important;
  box-shadow: 0 1px 20px 1px #7367f0 !important;
}

.realEstate-page .update-realEstate .btn-warning {
  -webkit-box-shadow: 0 1px 20px 1px #ff8510 !important;
  box-shadow: 0 1px 20px 1px #ff8510 !important;
}

.realEstate-page .dz-preview, .dz-file-preview {
  display: none;
}
</style>
